html, body {
	margin: 0;
	padding: 0;
	font-family: "Roboto", -apple-system, "San Francisco", "Segoe UI", "Helvetica Neue", sans-serif;
	font-size: 12pt;
	background-color: #eee;
}

* {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}
.page {
	margin: 0cm auto;
	background: #fff;
	box-shadow: 0 4px 5px rgba(75, 75, 75, 0.2);
	outline: 0;
}

div.page-break {
	page-break-after: always;
}

@media screen {

	div.page-break::before {
		content: "";
		display: block;
		height: 0.8cm;
		margin-left: 0.5cm;
		margin-right: 0.5cm;
		background-color: #fff;
		box-shadow: 0 6px 5px rgba(75, 75, 75, 0.2);
	}
	div.page-break {
		display: block;
		height: 1.8cm;
		margin-left: -2.5cm;
		margin-right: -2.5cm;
		margin-top: 1.2cm;
		margin-bottom: 2cm;
		background: #eee;
	}
}
h1 {
	page-break-before: always;
}
h1, h2, h3, h4, h5, h6 {
	page-break-after: avoid;
}
p {
	margin: 0;
}
p + p {
	margin-top: 0.5cm;
}
a {
	text-decoration: none;
	color: black;
}
table {
	page-break-inside: avoid;
}
@page {
	orphans: 4;
	widows: 2;
}
@media print {
	html, body {
		background-color: #fff;
	}
	.page {
		width: initial !important;
		min-height: initial !important;
		margin: 0 !important;
		padding: 0 !important;
		border: initial !important;
		border-radius: initial !important;
		background: initial !important;
		box-shadow: initial !important;
		page-break-after: always;
	}
}