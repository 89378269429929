/* Table */
.container-table {
  padding: 0px 80px 30px 80px;
  margin-top: 3.5vh;
}
.container-tableButton {
  margin-top: 3vh;
  text-align: center;
}
.dataTables_wrapper,
.table_footer {
  max-width: 1080px;
  font-size: 1em;
  margin-left: auto;
  margin-right: auto;
}
#data_table_wrapper {
  /* height: 500px; */
  text-align: left;
}
#data_table > thead > tr {
  height: 0px !important;
  border: none !important;
}
table.dataTable thead th,
table.dataTable thead td {
  border: none;
  background: var(--main-color);
  color: white;
}
.dataTables_length {
  padding: 17.5px;
  font-weight: bold;
}

/* Table Row */
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 5px 15px;
}
.center.pengiriman {
  font-size: 10px;
}

/* Search Button */
.dataTables_filter > label > input {
  border-radius: 15px;
  border: none;
  outline: none;
  width: 220px;
  height: 30px;
  font-size: 1em;
  padding-left: 10px;
}
.dataTables_filter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-weight: bold;
}

/* Scroll Bar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #e9e9e9;
}
::-webkit-scrollbar-thumb {
  background: var(--main-color);
}

/* Icon */
.icon_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.icon {
  width: 24px;
  padding: 5px;
}
.icon:hover {
  cursor: pointer;
  filter: invert(25%) sepia(96%) saturate(6726%) hue-rotate(351deg)
    brightness(82%) contrast(79%);
}

/* Button Table */
.btn-table-container {
  text-align: left;
  margin: 0px 60px;
}
#btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
#admin-aset {
  display: flex;
  padding-left: 50px;
  margin-top: 5px;
  justify-content: center;
}
#admin-aset.tambah-print-btn {
  padding-left: 0;
  margin-top: 15px;
}
#admin-aset.tambah-print-btn button {
  min-width: 120px;
  max-width: 120px;
  margin-left: 5px;
  margin-right: 5px;
}
#btn-tambah {
  width: 20%;
  font-size: 1em;
}

/* Table Footer */
td.pengiriman {
  display: flex;
  justify-content: space-around;
}
.container-pagination {
  display: flex;
  justify-content: center;
  height: 80px;
}
.table_footer {
  width: 100%;
  padding: 10px;
  max-height: 75px;
}

/* Modal */
.modal-body {
  text-align: center;
  word-break: break-word;
}
.modal-footer {
  justify-content: space-evenly !important;
}
ul {
  text-align: left;
}

/* Modal Loading */
.modal-loading-content > div > .modal-content {
  background: transparent;
  outline: none;
  border: none;
  height: 25vw;
}
.modal-loading-body {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-loading {
  width: 5vw !important;
  height: 5vw !important;
  border-color: var(--main-color) !important;
  border-right-color: transparent !important;
}

/* Nama Supir Pada Tabel Premi */
.dataTables_wrapper .dataTables_processing {
  z-index: 100;
  height: 30px !important;
  top: 55% !important;
  margin-top: 0 !important;
  padding: 0 !important;
  background: white !important;
}

.exp-container {
  text-align: center;
}

td.details-control {
  cursor: pointer;
  color: var(--main-color);
}

tr.row-detail > td {
  border-bottom: 2px solid #808080;
}

.row-info-detail {
  width: 18px;
  height: 18px;
}

.row-info-detail:hover {
  filter: invert(25%) sepia(96%) saturate(6726%) hue-rotate(351deg)
    brightness(82%) contrast(79%);
}

img.icon.detail {
  width: 28px;
  height: 30px;
  margin-top: 5px;
}
img.icon.detail.edit {
  width: 25px;
  margin-top: 0;
}

@media screen and (max-width: 1280px) {
  #admin-aset {
    padding: 0px;
  }
  .btn-admin-asset {
    min-width: 80px;
  }
  .dataTables_length {
    padding: 20.25px;
  }
}

@media (max-width: 575px) {
  #admin-aset.tambah-print-btn {
    margin-top: 0;
  }
  #admin-aset.tambah-print-btn button {
    min-width: 60px;
    max-width: 60px;
    font-size: 0.6em;
  }
  .row-info-detail {
    width: 15px;
    height: 15px;
  }
  .container-table #nama-supir h4 {
      font-size: 0.65em;
      padding: 3px 8px;
  }
  .container-table {
      margin-bottom: 4vh;
      padding: 0px 20px 20px 20px;
  }
  .dataTables_length {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 0.6em;
      box-shadow: none;
  }
  table.dataTable thead th, table.dataTable thead td,
  table.dataTable tbody th, table.dataTable tbody td {
      font-size: 0.6em;
      padding: 5px;
  }
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
      padding-top: 0;
      font-size: 0.6em;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
      padding: 0;
  }
  .dataTables_wrapper .dataTables_filter {
      margin-top: 10px;
      position: inherit;
      padding: 8px 5px;
  }
  .dataTables_wrapper .dataTables_filter label {
      margin-bottom: 5px;
      font-size: 0.6em;
  }
  .dataTables_filter > label > input {
      height: 15px;
      width: 100%;
      margin-top: 5px;
  }
  .btn-table-container {
      margin: 0;
  }
  #btn-container {
      margin-top: 3px;
      width: 100%;
  }
  #btn-tambah {
      font-size: 0.6em;
      padding: 2px 0;
      margin-top: 20px;
  }
  .table_footer {
      height: 60px;
      max-height: none;
  }
  .form-filter {
      padding: 0;
      margin-bottom: 15px;
  }
  .form-filter .form-label {
      font-size: 7px;
  }
  input[type="date"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control,
  input[type="time"].form-control {
      font-size: 0.5em;
      padding: 0 5px;
  }
  .form-group select.form-control {
      font-size: 0.5em;
      padding: 0 5px;
  }
  .form-filter button {
      font-size: 0.6em;
      margin-bottom: 5px;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  #admin-aset.tambah-print-btn {
    margin-top: 0;
  }
  .row-info-detail {
    width: 15px;
    height: 15px;
  }
  .container-table #nama-supir h4 {
      font-size: 0.65em;
      padding: 3px 8px;
  }
  .container-table {
      margin-bottom: 7vh;
      padding: 0px 30px 30px 30px;
  }
  .dataTables_length {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 0.7em;
      box-shadow: none;
  }
  table.dataTable thead th, table.dataTable thead td,
  table.dataTable tbody th, table.dataTable tbody td {
      font-size: 0.7em;
      padding: 8px;
  }
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
      padding-top: 0;
      font-size: 0.7em;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
      padding: 0;
  }
  .dataTables_wrapper .dataTables_filter {
    position: inherit;
  }
  .dataTables_wrapper .dataTables_filter label {
      margin-bottom: 5px;
      font-size: 0.7em;
      width: 100%;
  }
  .dataTables_wrapper .dataTables_filter input {
    width: 100%;
    margin-left: 0;
  }
  .dataTables_filter > label > input {
      height: 20px;
  }
  #btn-container {
      margin-top: 25px;
  }
  #btn-tambah {
      font-size: 0.7em;
      padding: 2px 0;
      margin-top: 25px;
  }
  #admin-aset.tambah-print-btn button {
    min-width: 90px;
    max-width: 90px;
    font-size: 0.8em;
  }
  .table_footer {
      height: 60px;
      max-height: none;
  }
  .form-filter {
      padding: 0;
      margin-bottom: 15px;
  }
  .form-filter .form-label {
      font-size: 10px;
  }
  input[type="date"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control,
  input[type="time"].form-control {
      font-size: 0.7em;
      padding: 0 5px;
  }
  .form-group select.form-control {
      font-size: 0.7em;
      padding: 0 5px;
  }
  .form-filter button {
      font-size: 0.7em;
      margin-bottom: 10px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  #admin-aset.tambah-print-btn {
    margin-top: 0;
  }
  #admin-aset.tambah-print-btn button {
    min-width: 100px;
    max-width: 100px;
    font-size: 0.9em;
  }
  .container-table #nama-supir h4 {
      font-size: 0.75em;
      padding: 5px 10px;
  }
  .dataTables_length {
      padding: 12px 12px 5px 12px;
      font-size: 0.75em;
  }
  table.dataTable thead th, table.dataTable thead td,
  table.dataTable tbody th, table.dataTable tbody td {
      font-size: 0.8em;
      padding: 10px;
  }
  .dataTables_wrapper .dataTables_info {
      font-size: 0.75em;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
      font-size: 0.75em;
      padding: 0 3px;
  }
  .dataTables_wrapper .dataTables_filter {
      width: 100%;
      font-size: 0.8em;
      box-shadow: 1px 6px 8px black;
  }
  .dataTables_filter > label > input {
      height: 25px;
  }
  #btn-tambah {
      font-size: 0.75em;
      margin-top: 0;
  }
  .form-filter {
      padding: 0;
  }
  input[type="date"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control,
  input[type="time"].form-control {
      font-size: 0.75em;
      padding: 0 5px;
  }
  .form-group select.form-control {
      font-size: 0.75em;
      padding: 0 5px;
  }
  .form-filter button {
      font-size: 0.8em;
      margin-bottom: 10px;
  }
  .table_footer {
      max-height: 60px;
  }
}

@media (min-width: 991px) and (max-width: 1440px) {
  .main {
      margin-left: 340px;
      margin-right: 35px;
  }
}